import React from "react"

import SiteLayout from "../../components/layout/site"

export default () => {
  return (
    <SiteLayout title="Privacy Policy">
      <section className="section section-lg">
        <div className="container">
          <h1>Privacy Policy</h1>

          <p className="text-right">Updated on 1 November 2016</p>

          <p>
            This Privacy Policy (this “Policy”) as may be amended, modified or
            supplemented from time to time, applies to all visitors accessing
            and/or using the Liquid Pay App and/or Liquid Pay website (such
            persons collectively referred to as “you”) and is meant to help you
            understand how Liquid Group Pte. Ltd. and its related corporations
            (“Liquid Pay” or “we” or “us”) collect, use, disclose, transfer and
            store your personal information so please take time to read it
            carefully. All capitalised terms used in this Policy shall have the
            same meaning as those ascribed in the Terms of Use unless otherwise
            stated or defined herein.
          </p>
          <p>
            By signing-up as a registered user, accessing or using the Liquid
            Pay App, Liquid Pay website or any of our Services, you are
            accepting this Policy and consenting to the collection, use and/or
            disclosure of your information disclosed to us in the manner as
            explained in this Policy. If you do not agree with this Policy,
            please do not use or access the Liquid Pay App and/or Liquid Pay
            website.
          </p>
          <h3>INFORMATION COLLECTION, USE AND DISCLOSURE</h3>
          <p>
            The information we may collect includes (but is not limited to) the
            following:
          </p>

          <p>
            Information you provide to us: We may collect and store information
            you disclose to us when you sign up on the Liquid Pay App as a User
            and/or access and/or use (whether as a User or otherwise) the Liquid
            Pay App and/or Liquid Pay website. We may ask you for your personal
            information, including but not limited to, your first name, last
            name, email address, mobile number, gender and date of birth at the
            point of signing up or registration. Once you have successfully
            registered with us as a User, you may not be anonymous to us. We may
            also collect and store information when you interact with us
            including, but not limited to, instances when you add or update your
            User Account details via the Liquid Pay App, when you upload an
            image of yourself under your User Account details, when you
            participate in activities on the Liquid Pay App and/or Liquid Pay
            website, when you add your credit/debit/charge card or bank account
            details to your User Account, or when you correspond with us
            regarding the Liquid Pay App and/or Liquid Pay website.
          </p>

          <p>
            Information we collect automatically: When you use the Liquid Pay
            App and/or Liquid Pay website, we may collect information sent to us
            by your computer, mobile phone or another access device. Such
            information includes, but is not limited to, details of your page
            visits or mobile application usage, your computer or mobile devices’
            configuration such as (but not limited to) your IP address,
            location, your transactions executed within the Liquid Pay App,
            screens viewed within the Liquid Pay App, cookie information and the
            pages you requested.
          </p>

          <p>
            Locational information: When accessing and/or using the Liquid Pay
            App and/or Liquid Pay website, we may collect and store your
            locational information if permission has been explicitly granted to
            the Liquid Pay App and/or Liquid Pay website by you, in order to
            verify or authenticate transactions as well as to improve your user
            experience through localized content. The Liquid Pay App and/or
            Liquid Pay website may use technologies available through the
            devices including but not limited to GPS, WiFi, IP address and cell
            towers to generate your location, unless you configure your
            operating system settings to prevent our collection of locational
            information.
          </p>

          <p>
            Other information: By taking up any Services or participating in any
            promotions or surveys or by completing certain tasks or actions on
            the Liquid Pay App and/or Liquid Pay website, you consent to sharing
            such information you provide with us and the relevant Third Party
            Service Providers. We may share this information with our Third
            Party Service Providers and we may use such information to match
            offers and services to your profile. The information shared with our
            Third Party Service Providers may be used at such Third Party
            Service Providers’ discretion and in accordance with such Third
            Party Service Providers’ privacy policies. Liquid Pay does not
            assume liability or responsibility of how the information is used by
            such Third Party Service Providers. We will share your information,
            including personal information, locational information, friends
            list(s), and moments with certain social networking or other third
            party applications or services, if you choose to connect these to
            your User Account. Subject to compliance with privacy laws from time
            to time and without prejudice to the above, we may also use any
            information we obtain about your activity on the Liquid Pay App, or
            in connection with our Services, on an anonymised basis.
          </p>

          <p>
            Collection, use and/or disclosure of information: We may collect,
            use and/or disclose information for the following purposes (among
            others):-
          </p>

          <ul>
            <li>
              to help us further create, develop, operate, deliver, and improve
              the Liquid Pay App and/or Liquid Pay website and/or New Services;
            </li>
            <li>
              to administer, manage and operate the Liquid Pay platform, to
              provide the Services through the Liquid Pay App and/or Liquid Pay
              website and such other channels as may be available from time to
              time, and to provide customer service to you;
            </li>
            <li>to conduct marketing promotions;</li>
            <li>
              to help us to verify identity, protect our systems and customers,
              ensure the integrity and operation of our businesses and systems
              and for loss prevention and anti-fraud purposes;
            </li>
            <li>
              to send important notices, such as communications about purchases
              and changes to our terms, conditions, and policies;
            </li>
            <li>
              to offer you tailored content, such as giving you updates on
              special offers and reward schemes;
            </li>
            <li>
              for internal purposes such as auditing, data analysis, and
              research to improve the Liquid Pay App and/or Liquid Pay website
              and customer communications;
            </li>
            <li>
              where collected from cookies and other technologies, like pixel
              tags, to improve your user experience and the quality and scope of
              our services;
            </li>
            <li>
              to comply with applicable laws, regulations and other requirements
              (e.g. providing assistance to law enforcement agencies, regulatory
              authorities and other governmental agencies, cooperating with
              governmental requests, performing internal audits);
            </li>
            <li>
              to report any activity that we suspect violates any law or
              regulation to appropriate law enforcement agencies, regulatory
              authorities and other governmental agencies;
            </li>
            <li>
              to respond to subpoenas, court orders, or legal process, or to
              establish or exercise our legal rights or defend against any legal
              claims; and/or
            </li>
            <li>
              to investigate, prevent, or take action regarding illegal
              activities, suspected fraud, situations involving potential
              threats to the physical safety of any person, violations of the
              Terms of Use, the terms of any Third Party Service Provider, any
              terms relating to any particular offer, promotion or task, or as
              otherwise required by law.
            </li>
          </ul>

          <p>
            We may process your personal information on a server located outside
            the country where you live.
          </p>
          <p>
            We may, from time to time, use the services of Third Party Service
            Providers and other third parties in connection with the Liquid Pay
            App and/or Liquid Pay website. As such, we may share your
            information with these persons who are working with us to perform
            functions and process user data on our behalf. Rest assured that
            such access to your personal information will only be to perform
            services on our behalf and such third parties are obligated
            contractually to us not to disclose it or use it for any other
            purposes. In connection with the foregoing, some of these third
            parties, such as analytics providers, may collect information about
            your online activities over time and across different websites or
            online services when you use our services. We are not responsible
            for the actions or compliance of third parties, nor are we
            responsible for any additional information you provide directly to
            any third parties.
          </p>
          <p>
            In the event of a potential, proposed or actual sale or disposal of
            business or interest, merger, acquisition, reorganization or funding
            exercise relating to Liquid Pay (including negotiations thereof) or
            any other situation where personal information may be disclosed or
            transferred as one of our business assets, we will notify you before
            personal information about you is transferred and becomes subject to
            a different privacy policy.
          </p>
          <p>
            Accessing and updating your personal information: When updating your
            personal information with us, we may ask you to verify your identity
            before we act on your request. To the extent permitted by law, we
            may reject requests that are unreasonably repetitive, require
            disproportionate technical effort, risk the privacy of others, or
            would be extremely impractical. Whilst we do impose any fees or
            charges to provide information access and/or make any corrections,
            we reserve the right to do so where it would require a
            disproportionate amount of effort on our part. In the event that you
            choose to close your User Account with us, we may still continue to
            retain personal information on you in our databases after your User
            Account has been closed, for legitimate business or legal purposes.
          </p>
          <h3>MERCHANTS AND THIRD PARTY SERVICE PROVIDERS</h3>
          <p>
            We work with Merchants, banks or other Third Party Service Providers
            to enable them to accept payments from you using Liquid Pay. In
            doing so and in order for us to process your payments via the Liquid
            Pay App, we may share information about you with such party, such as
            your name, contact information, any image or profile picture you
            upload onto the Liquid Pay App, date of sign-up, the details of your
            payment transaction and number of payments you have made. We may
            also use this information to confirm to that party that you are a
            User.
          </p>
          <p>
            We may also work with Merchants, banks or other Third Party Service
            Providers to enable them to offer their own pre-paid electronic
            cards or wallets on our Liquid Pay App. In doing so, we may share
            information about you with such party, such as your name, mobile
            phone number, NRIC or passport number or Liquid Pay username and any
            other information that you may disclose to us when you choose to
            register for and use their pre-paid electronic cards or wallets on
            our Liquid Pay App.
          </p>
          <p>
            We do not disclose your credit/debit/charge card number or bank
            account number to anyone you have paid using our Liquid Pay App,
            except with your express permission or if we are required to do so
            in order to comply with a court order or other legal process. We do
            not store your bank account and credit/debit/charge card information
            that you provide to us on the Liquid Pay App or your mobile device.
            Your credit/debit/charge card information is tokenised once
            successfully verified and accepted by the Liquid Pay App. Through
            tokenization, all sensitive data on your credit/debit/charge card
            will be replaced with a unique identifier that generally cannot be
            mathematically reversed.
          </p>
          <h3>INFORMATION SHARING AND DISCLOSURE</h3>
          <p>
            We do not distribute your personal information to any third party
            unaffiliated with Liquid Pay or who is not a Merchant or Third Party
            Service Provider, except when we have your permission to do so.
          </p>
          <p>
            In the event of a potential, proposed or actual sale or disposal of
            business or interest, merger, acquisition, reorganisation or funding
            exercise relating to Liquid Pay (including negotiations thereof) or
            any other situation where personal information may be disclosed or
            transferred as one of our business assets, we will notify you before
            personal information about you is transferred and becomes subject to
            a different privacy policy.
          </p>
          <p>
            You agree that by opting to participate in any promotions,
            activities or surveys relating to Liquid Pay from time to time, you
            consent to Liquid Pay sharing all information (including any
            personally identifiable information) that we collect from you with
            the relevant Merchant or Third Party Service Providers in connection
            with the relevant activity, survey or promotion.
          </p>
          <h3>COOKIES</h3>
          <p>
            We may set and access cookies on your computer or mobile device. Our
            cookies will not store any personal information about you. We use
            cookies only to identify you as a User.
          </p>
          <h3>ACCOUNT INFORMATION</h3>
          <p>
            We reserve the right to send you certain communications relating to
            our Services, such as service announcements, administrative messages
            and service notifications that are considered part of your account
            with Liquid Pay, without offering you the opportunity to opt-out of
            receiving them.
          </p>
          <p>
            You may close your User Account at any time by e-mailing
            contact@liquidpay.com and requesting that we close your account. You
            will not be able to recover, reverse, cancel or ask for any refund
            of any transaction that is in process once your User Account has
            been closed. To the extent permitted by law, we reserve the right to
            use any personal information that may have been collected during
            your visits to www.liquidpay.com or through your use of the Liquid
            Pay App even after your account has been permanently closed for
            aggregated reporting and analysis purposes, or for such other
            purposes as may be permitted by you or by law.
          </p>
          <h3>DATA STORAGE</h3>
          <p>
            We may store and process your personal information using third party
            servers located in secure data centers in Singapore or elsewhere or
            in the cloud. This information is protected by physical, electronic
            and procedural safeguards in compliance with applicable regulations.
            We also use computer safeguards such as firewalls and data
            encryption. We authorize access to personal information only for
            those employees who require it to fulfill their job
            responsibilities.
          </p>
          <p>
            We make reasonable efforts to ensure security on our systems.
            Despite our efforts, we cannot guarantee that personal information
            may not be accessed, disclosed, altered or destroyed by breach of
            our administrative, managerial and technical safeguards. Therefore,
            we urge you to take adequate precautions to protect your personal
            data as well, including never sharing your User Account password
            with anyone and ensuring that access to your mobile device is
            password/pin/biometrics-protected.
          </p>
          <h3>CHANGES TO THIS POLICY</h3>
          <p>
            We reserve the right to revise, supplement or update this Policy
            from time to time by posting the revised version on the Liquid Pay
            App and/or Liquid Pay website or such other method of notification
            as may be prescribed by Liquid Pay to ensure that it is consistent
            with any industry trends and/or any changes in legal or regulatory
            requirements. Subject to your rights at law, you agree to be bound
            by the prevailing terms of this Policy as updated from time to time
            on our website www.liquidpay.com or the Liquid Pay App.
          </p>
          <h3>QUESTIONS</h3>
          <p>
            If you have questions or suggestions about the collection, use
            and/or disclosure of your personal information or this Policy or
            would like to receive information about the personal information we
            have in relation to you, to update such personal information or to
            withdraw any consent you have provided to us previously, please
            contact us at contact@liquidpay.com.
          </p>
        </div>
      </section>
    </SiteLayout>
  )
}
